import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { useParamsSelector } from 'hooks'
import { useInfiniteQuery } from 'react-query'
import { ApiResponseTypes, CustomerServices } from 'services'
import { getNextPageParam } from 'utils/getNextParam'
import { UseQueryOptionsTypes } from '../queries.types'

export const useInfiniteCustomersQuery = (
  options: UseQueryOptionsTypes | undefined = {}
) => {
  const params = useParamsSelector()

  const queryParams = options.useParams ? params.objParams : {}

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['/customers'],
    (paginatedParams) =>
      CustomerServices.paginated({
        params: {
          page: paginatedParams.pageParam,
          search: queryParams.search
        }
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      },
      enabled: options.enabled
    }
  )

  return {
    ...infiniteQuery,
    data: data?.pages.flatMap((page) => page.data) || [],
    dataCount: data?.pages.flatMap((page) => page.pagination.dataCount)[0] || 0
  }
}
