import { ApiResponseTypes, licenseApi } from 'services'
import {
  CustomerTypes,
  InvoiceFromCustomerPaginatedListTypes,
  InvoiceFromCustomerPayloadTypes
} from './Customers.types'

const postInvoiceFromCustomer = async ({
  customerId,
  payload
}: {
  customerId: CustomerTypes['id']
  payload: Partial<InvoiceFromCustomerPayloadTypes>
}) => {
  const { data: response } = await licenseApi.post<
    ApiResponseTypes<InvoiceFromCustomerPaginatedListTypes>
  >(`/customers/${customerId}/invoices`, payload)

  return response.data
}

export default {
  post: postInvoiceFromCustomer
}
