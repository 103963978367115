import { ApiResponseTypes } from 'services/api/api.types'
import { licenseApi } from 'services/license/license.api'
import { CustomerPayloadTypes, CustomerTypes } from './Customers.types'

const customerPost = async (payload: Partial<CustomerTypes>) => {
  const { data: response } = await licenseApi.post<
    ApiResponseTypes<CustomerTypes>
  >(`/customers`, payload)

  return response.data
}

const customerGet = async (id: CustomerTypes['id']) => {
  const { data: response } = await licenseApi.get<
    ApiResponseTypes<CustomerTypes>
  >(`/customers/${id}`)

  return response.data
}

const customerDelete = async (id: CustomerTypes['id']) => {
  return licenseApi.delete<CustomerTypes>(`/customers/${id}`)
}

const customerPut = async (
  id: CustomerTypes['id'],
  payload: Partial<CustomerPayloadTypes>
) => {
  const { data: response } = await licenseApi.put<
    ApiResponseTypes<CustomerTypes>
  >(`/customers/${id}`, payload)

  return response.data
}

export default {
  get: customerGet,
  post: customerPost,
  delete: customerDelete,
  put: customerPut
}
