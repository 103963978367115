import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useGroup } from 'context'

import { Button, DialogFooter } from 'common/components'
import { useGroupDialogContext } from '../../context'

import { GroupDialogFooterPropTypes } from './GroupDialogFooter.types'

export const GroupDialogFooterComponent: React.FC<
  GroupDialogFooterPropTypes
> = ({ group, ...rest }) => {
  const { t } = useTranslation()

  const { createUserGroupAsync, updateUserGroupAsync } = useGroup()

  const {
    handleClose,
    isCustomGroup,
    isFeideGroup,
    isCreation,
    isEdition,
    createdUserGroup,
    updatedUserGroup
  } = useGroupDialogContext()

  const handleCreateGroup = async () => {
    if (isCreation) {
      await createUserGroupAsync(createdUserGroup)
      handleClose()
    }
  }

  const handleEditGroup = async () => {
    if (isEdition) {
      if (group) {
        updateUserGroupAsync({
          userGroupId: group.id,
          payload: updatedUserGroup
        })
      }
      handleClose()
    }
  }

  return (
    <DialogFooter {...rest}>
      <Box justifyContent='flex-end' display='flex' gap='1.6rem'>
        {isFeideGroup && (
          <Button onClick={handleClose} variant='contained'>
            {t('general.actions.close')}
          </Button>
        )}
        {isCustomGroup && (
          <>
            <Button onClick={handleClose} variant='outlined'>
              {t('general.actions.cancel')}
            </Button>
            {isCreation && (
              <Button onClick={handleCreateGroup} variant='contained'>
                {t('general.actions.createGroup')}
              </Button>
            )}
            {isEdition && (
              <Button onClick={handleEditGroup} variant='contained'>
                {t('general.actions.saveGroup')}
              </Button>
            )}
          </>
        )}
      </Box>
    </DialogFooter>
  )
}
