import { ApiResponseTypes, licenseApi } from 'services'
import {
  CustomerTypes,
  LicenseFromCustomerPaginatedListTypes,
  LicenseFromCustomerPayloadTypes
} from './Customers.types'

const postLicenseFromCustomer = async ({
  customerId,
  payload
}: {
  customerId: CustomerTypes['id']
  payload: Partial<LicenseFromCustomerPayloadTypes>
}) => {
  const { data: response } = await licenseApi.post<
    ApiResponseTypes<LicenseFromCustomerPaginatedListTypes>
  >(`/customers/${customerId}/licenses`, payload)

  return response.data
}

const putLicenseFromCustomer = async ({
  customerId,
  payload
}: {
  customerId: CustomerTypes['id']
  payload: LicenseFromCustomerPayloadTypes[]
}) => {
  const { data: response } = await licenseApi.put<
    ApiResponseTypes<LicenseFromCustomerPaginatedListTypes[]>
  >(`/customers/${customerId}/licenses`, payload)

  return response.data
}

export default {
  post: postLicenseFromCustomer,
  put: putLicenseFromCustomer
}
