import {
  ApiPaginatedParamTypes,
  PaginatedResponse
} from 'services/api/api.types'
import { makeQueryParams } from 'utils/makeQueryParams'
import { licenseApi } from '../../license.api'
import {
  CustomerTypes,
  LicenseFromCustomerPaginatedListTypes
} from './Customers.types'

export const paginated = async ({
  customerId,
  params: { page = 1, pageSize = 20, search }
}: {
  customerId: CustomerTypes['id']
  params: ApiPaginatedParamTypes['params']
}) => {
  const response = await licenseApi.get<
    PaginatedResponse<LicenseFromCustomerPaginatedListTypes>
  >(
    `/customers/${customerId}/licenses${makeQueryParams({
      selectedPage: page,
      search,
      pageSize
    })}`
  )

  return response.data
}

export default paginated
