import { GeneralLocaleTypes } from '../locales.types'

const generalLocaleBo: GeneralLocaleTypes = {
  minute: 'minutt',
  minutes: 'minutter',
  min: 'min',

  showingOf: 'Viser {{ visibleRows }} av {{ totalDataRows }}',
  showLess: 'Vis mindre',
  showMore: 'Vis mer',

  invalid: 'Ugyldig',

  fileSelect: 'Velg en fil',

  userNotFound: 'Bruker ikke funnet',
  noMoreResults: 'Ingen flere resultater',
  loadMoreResults: 'Last inn flere resultater',
  yes: 'Ja',
  no: 'Nei',
  teacher: 'Lærer',

  assignment_one: 'Oppgave',
  assignment_other: 'Oppgaver',

  entry_one: 'Entry',
  entry_other: 'Entries',

  group_one: 'Gruppe',
  group_other: 'Grupper',

  somethingAndAnotherThing: '{{ something }} og {{ anotherThing }}',

  feideFetchStatus: {
    title: 'Feide-synkronisering...',
    fetching: 'Oppdaterer database med Feide-informasjon',
    error: 'Det oppstod en feil under forsøk på å hente informasjon fra feide',
    success: 'Innhenting av brukerdata fra Feide var vellykket.'
  },

  filters: {
    text: {
      filterBy: 'Filtrer etter:',
      filterResults: 'Filtrerings resultater',
      clearFilters: 'Fjern filtre',
      selectItem: 'Velg et {{ topic }}'
    },
    status: {
      title: 'Status',
      item: {
        allAssignments: 'alle oppgaver',
        progress: 'aktiv',
        upcoming: 'planlagt',
        finished: 'inaktiv'
      }
    },
    topics: {
      title: 'Temaer'
    },
    subjects: {
      title: 'Emne',
      item: {
        multiplication: 'Multiplikasjon',
        probability: 'Sannsynlighet',
        division: 'Divisjon',
        fraction: 'Brøk',
        additionAndSubtraction: 'Addisjon og subtraksjon',
        negativeNumbers: 'Negative tall'
      }
    },
    assigned: {
      title: 'Tildelt',
      item: {
        students: 'elever',
        groups: 'grupper'
      }
    },
    planets: {
      title: 'Planeter'
    },
    skills: {
      title: 'Ferdigheter'
    },
    groups: {
      title: 'Alle Grupper'
    }
  },
  imageAlt: {
    login: {
      gameCharacters: 'Spillkarakterer'
    }
  },
  status: {
    error: {
      notFound: {
        title: 'Oops! <br/> Vi finner ikke siden du leter etter.',
        description:
          'Dette kan skyldes at siden ikke finnes lenger, eller at innholdet har blitt flyttet. <customLink>Gå til forsiden</customLink> eller velg en annen side fra hovedmenyen.'
      },
      somethingWentWrong: 'Noe gikk galt',
      cannotUndoThisAction:
        'Du kan <strong>ikke</strong> angre denne handlingen.'
    },
    active: 'Aktiv',
    inactive: 'Inactive',
    payment: {
      paid: 'Betalt',
      notPaid: 'Ikke betalt',
      sent: 'Sendt',
      notSent: 'Ikke sendt'
    }
  },
  state: {
    nrOfModesSelected: '{{ count }} Modus valgt',
    nrOfModesSelected_plural: '{{ count }} moduser valgt',
    nrOfModesSelected_0: 'Ingen moduser valgt',
    selected: 'Valgt',
    notSelected: 'Ikke valgt',
    selectedNumber: '{{ selectedNumber }} valgt',
    removed: 'Fjernet',
    deleted: 'Slettet',
    modified: 'Modifisert',
    started: 'Påbegynt',
    ended: 'Avsluttet',
    comingSoon: 'Kommer snart',
    quitEditing: 'Forlat siden?',
    searchResultsFor: 'Søkeresultater for {{ value }}',
    loading: 'Laster...',
    studentAdded: 'Elev lagt til',
    ok: 'Ok'
  },
  selectAll: 'Velg alle',
  actions: {
    viewReport: 'Se rapport',
    add: 'Legg til',
    addNewTopic: 'Legg til et oppdrag',
    addAndClose: 'Legg til og lukk',
    addAndContinue: 'Legg til og fortsett',
    edit: 'Rediger',
    view: 'Åpne',
    delete: 'Slett',
    remove: 'Fjern',
    next: 'Neste',
    back: 'Tilbake',
    createGroup: 'Lag ny gruppe',
    saveGroup: 'Lag ny gruppe',
    save: 'Lagre {{ addOn }}',
    saveChanges: 'Lagre endringer',
    saveCustomer: 'Redd kunde',
    update: 'Oppdater {{ addOn }}',
    select: 'Velg',
    confirm: 'Bekreft',
    cancel: 'Avbryt',
    report: 'Se rapport',
    editParentsName: 'Rediger foresattes navn',
    moveToGroup: 'Flytt til en gruppe',
    moreOptions: 'Flere valg',
    close: 'Lukk',
    revoke: 'Fjern',
    goToArticle: 'Gå til artikkelen',
    keepEditing: 'Rediger videre',
    quit: 'Ja, forlat',
    show: 'Vis',
    hide: 'Skjul',
    unHide: 'Gjenopprett'
  },
  user: {
    accountSettings: 'Kontoinnstillinger'
  },
  errors: {
    invalidDate: 'Ugyldig dato',
    invalidTime: 'Ugyldig tid',
    shouldNotBeEmpty: 'Skal ikke være tom',
    shouldNotBeEmptyAddon: '{{ key }} skal ikke være tom',
    unexpectedError: 'Uventet feil',
    notSaved: 'Dine endringer vil <strong>ikke</strong> bli lagret.'
  },
  validation: {
    required: 'Dette er et obligatorisk felt'
  },
  dealTypes: {
    municipality: 'Kommune',
    school: 'Skole'
  }
}

export default generalLocaleBo
