export default {
  mainMenu: {
    title: 'Main Menu',
    titleMobile: 'More'
  },
  menu: {
    title: 'Menu'
  },
  assignments: {
    title: 'Assignments',
    subtitle: 'This is a overview of all your assignments',
    newTitle: 'Create assignment',
    components: {
      contentModal: {
        subtitle:
          'Select new missions to add to the assignment. Know more about each game by clicking on <strong>“More Info”</strong> on card. You can also start to create an assignment by selecting games below.'
      }
    },
    create: {
      title: 'Create assignment',
      subtitle:
        'An assignment is an assortment of missions that you share with your students, based on what you wish to be the focus of the lesson or homework. To create an assignment add your desired preferences below and press “Save Assignment”. The students will find the assignment in the Mission Board inside the game. All fields are mandatory. Additionally, you must add a mission to save the assignment.'
    },
    edit: {
      title: 'Editing "{{ name }}" assignment',
      subtitle: 'Please, edit your assignment here'
    }
  },
  library: {
    title: 'Library',
    subTitle: 'This is a list of our available games'
  },
  reports: {
    title: 'Reports',
    subtitle: 'This is a overview of all your reports'
  },
  offlineContent: {
    title: 'Worksheets',
    titleMobile: 'Content',
    subtitle:
      'Search and download pre made worksheets based on Numetry’s game modes.'
  },
  pdfGenerator: {
    title: 'PDF Generator',
    subtitle:
      'Try our new tool to create your own worksheets. Choose arithmetic operations and settings for your worksheet. See a preview while you create it. When you are ready, press "Create Worksheet" to download or print it.',
    generators: {
      multiplication: {
        title: 'Multiplication Generator',
        breadcrumb: {
          title: 'Multiplication'
        }
      },
      division: {
        title: 'Division Generator',
        breadcrumb: {
          title: 'Division'
        }
      },
      additionAndSubtraction: {
        title: 'Addition and Subtraction Generator',
        subtitle: '',
        breadcrumb: {
          title: 'Addition and Subtraction'
        }
      },
      fraction: {
        title: 'Fraction Generator',
        breadcrumb: {
          title: 'Fraction'
        }
      }
    }
  },
  groups: {
    title: 'Groups',
    subtitle: 'This is a overview of all your groups'
  },
  license: {
    title: 'License',
    tabs: {
      manage: {
        title: 'License Management',
        subtitle: 'This is a overview of all your licenses',
        allSchools: 'All schools'
      },
      buy: {
        title: 'Buy License'
      }
    }
  },
  helpAndSupport: {
    title: 'Help & Support',
    subtitle: `Numetry's customer service can help you if you have questions or experience problems. Here you will find customer service's opening hours and contact information. Outside our opening hours, you will find answers to frequently asked questions in our help center.`
  },
  settings: {
    title: 'Account settings'
  },
  customerOverview: {
    title: 'Customer Overview',
    subtitle: 'This is a overview of all your deals'
  },
  feedback: {
    title: 'Give us a feedback',
    popover: {
      title: 'What do you think of Numetry School?',
      input: {
        placeholder: 'Feel free to elaborate'
      },
      moods: {
        bad: 'Bad',
        dissatisfied: 'Dissatisfied',
        neutral: 'Neutral',
        satisfied: 'Satisfied',
        verySatisfied: 'Very Satisfied'
      },
      button: {
        text: 'Submit'
      },
      success: {
        title: 'Thank you very much!'
      }
    }
  }
}
