import dealIdMethods from './Deals.id'
import paginated from './Deals.paginated'

export const DealServices = {
  id: {
    ...dealIdMethods
  },
  ...paginated
}

export * from './Deals.types'
