import { ApiResponseTypes, UserInfoTypes } from 'services'
import { DealTypes, licenseApi } from '../../license.api'
import { LicenseTypes } from './Licenses.types'

const licensePost = async (payload: LicenseTypes) => {
  const { data: response } = await licenseApi.post<
    ApiResponseTypes<LicenseTypes>
  >(`/licenses`, payload)

  return response.data
}

const licenseGet = async (id: LicenseTypes['id']) => {
  const { data: response } = await licenseApi.get<
    ApiResponseTypes<LicenseTypes>
  >(`/licenses/${id}`)

  return response.data
}

const licenseDelete = async (id: LicenseTypes['id']) => {
  return licenseApi.delete<LicenseTypes>(`/licenses/${id}`)
}

export const assignLicenseToStudent = async ({
  userId,
  dealId
}: {
  userId: UserInfoTypes['id']
  dealId: DealTypes['id']
}) => {
  return licenseApi.patch<LicenseTypes>(
    `/licenses/assign/${userId}/deal/${dealId}`
  )
}

export const revokeLicenseToStudent = async ({
  userId,
  licenseId
}: {
  userId: UserInfoTypes['id']
  licenseId: LicenseTypes['id']
}) => {
  return licenseApi.patch<LicenseTypes>(
    `/licenses/assign/${userId}/revoke/${licenseId}`
  )
}

export default {
  get: licenseGet,
  post: licensePost,
  delete: licenseDelete
}
