import customerMethods from './Customers'
import customerIdMethods from './Customers.id'
import paginated from './Customers.paginated'
import paginatedLicenses from './Customers.id.licenses.paginated'
import paginatedInvoices from './Customer.id.invoices.paginated'
import customerIdLicenseMethods from './Customers.id.licenses'
import customerIdInvoiceMethods from './Customers.id.invoices'

export const CustomerServices = {
  ...customerMethods,
  id: {
    ...customerIdMethods,
    licenses: {
      ...customerIdLicenseMethods,
      paginated: paginatedLicenses
    },
    invoices: {
      ...customerIdInvoiceMethods,
      paginated: paginatedInvoices
    }
  },
  paginated
}

export * from './Customers.types'
