/* eslint-disable prettier/prettier */
export const ROUTES = {
  // Base
  BASE_ROOT: '/',
  UNKNOWN: '*',

  // Panels
  TEACHER_PANEL: '/',
  SALES_PANEL: 'sales-panel',

  // [Auth] - Views and subViews
  AUTH: 'auth',
  LOGIN: 'login',
  LOGOUT: 'logout',

  // [OfflineContent] - Views and subViews
  OFFLINE_CONTENT: 'offline-content',

  // [PdfGenerator] - Views and subViews
  PDF_GENERATOR: 'pdf-generator',
  ADDITION_AND_SUBTRACTION: 'addition-and-subtraction',
  DIVISION: 'division',
  FRACTION: 'fraction',
  MULTIPLICATION: 'multiplication',
  DOWNLOAD: 'download',

  // [Customer] - Views and subViews
  SALES_TEAM: 'sales-team',

  // Views
  CREATE_ASSIGNMENT: 'create-assignment',
  ASSIGNMENT: 'assignment',
  GROUPS: 'groups',
  SETTINGS: 'settings',
  HELP_SUPPORT: 'help-support',
  CUSTOMER_OVERVIEW: 'customer-overview',
  LIBRARY: 'library',

  // [License] - Views and subViews
  LICENSE: 'license',
  SCHOOLS: 'schools',
  TEACHERS: 'teachers',
  CLASSES: 'classes',
  
  // [Report] - Views and subViews
  REPORTS: 'report',
  STUDENTS: 'students',
  ASSIGNMENTS: 'assignments',
  GAMES: 'games',
  GAME_MODES: 'game-modes',

  // Actions
  EDIT: 'edit',

  // Params
  ID: ':id',
  TYPE: ':type',
  SCHOOL_ID: ':schoolId',
  CLASS_ID: ':classId',
  GROUP_ID: ':groupId',
  STUDENT_ID: ':studentId',
  ASSIGNMENT_ID: ':assignmentId',
  GAME_ID: ':gameId',
  GAME_MODE_ID: ':gameModeId',

  ADD_CONTENT: 'add-content'
} as const

export type RouteTypes = typeof ROUTES[keyof typeof ROUTES] | 'default'
