import {
  ApiPaginatedParamTypes,
  PaginatedResponse
} from 'services/api/api.types'
import { makeQueryParams } from 'utils/makeQueryParams'
import { licenseApi } from '../../license.api'
import { CustomerFromPaginatedListTypes } from './Customers.types'

export const paginated = async ({
  params: { page = 1, pageSize = 20, search }
}: ApiPaginatedParamTypes) => {
  const response = await licenseApi.get<
    PaginatedResponse<CustomerFromPaginatedListTypes>
  >(
    `/customers${makeQueryParams({
      selectedPage: page,
      search,
      pageSize
    })}`
  )

  return response.data
}

export default paginated
