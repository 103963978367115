import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { useParamsSelector } from 'hooks'
import { useInfiniteQuery } from 'react-query'
import { ApiResponseTypes, CustomerServices, CustomerTypes } from 'services'
import { getNextPageParam } from 'utils/getNextParam'

export const useInfiniteLicensesFromCustomerIdQuery = (
  customerId: CustomerTypes['id']
) => {
  const params = useParamsSelector()

  const queryParams = params.objParams

  const { alert } = useFeedback()

  const { data, ...infiniteQuery } = useInfiniteQuery(
    ['/customers/customerId/licenses', customerId, queryParams],
    (paginatedParams) =>
      CustomerServices.id.licenses.paginated({
        customerId,
        params: {
          page: paginatedParams.pageParam,
          search: queryParams.search
        }
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
        alert.showError(error.response?.data.message || error.message)
      }
    }
  )

  return {
    ...infiniteQuery,
    data: data?.pages.flatMap((page) => page.data),
    dataCount: data?.pages.flatMap((page) => page.pagination.dataCount)[0] || 0
  }
}
