import { ApiResponseTypes, licenseApi } from 'services'
import { CustomerPayloadTypes, CustomerTypes } from './Customers.types'

const postCustomer = async (payload: CustomerPayloadTypes) => {
  const { data: response } = await licenseApi.post<
    ApiResponseTypes<CustomerTypes>
  >('/customers', payload)

  return response.data
}

export default {
  post: postCustomer
}
