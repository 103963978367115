import {
  ApiPaginatedParamTypes,
  PaginatedResponse
} from 'services/api/api.types'
import { makeQueryParams } from 'utils/makeQueryParams'
import { licenseApi } from '../../license.api'
import { DealTypes } from './Deals.types'

export const paginated = async ({
  params: { page = 1, pageSize = 20, search }
}: ApiPaginatedParamTypes) => {
  return licenseApi
    .get<PaginatedResponse<DealTypes>>(
      `/deal${makeQueryParams({
        selectedPage: page,
        search,
        pageSize
      })}`
    )
    .then((response) => response.data)
}

export default {
  paginated
}
