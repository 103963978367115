import {
  api,
  ApiResponseTypes,
  CustomerTypes,
  licenseApi,
  DealTypes,
  InvoiceTypes
} from 'services'

const dealPost = async (
  customerId: CustomerTypes['id'],
  payload: Partial<DealTypes>
) => {
  const { data: response } = await licenseApi.post<ApiResponseTypes<DealTypes>>(
    `/deals/${customerId}`,
    payload
  )

  return response.data
}

const dealGet = async (dealId: DealTypes['id']) => {
  const { data: response } = await licenseApi.get<ApiResponseTypes<DealTypes>>(
    `/deals/${dealId}`
  )

  return response.data
}

const dealPatch = async (
  dealId: DealTypes['id'],
  payload: Partial<DealTypes>
) => {
  const { data: response } = await licenseApi.patch<
    ApiResponseTypes<DealTypes>
  >(`/deals/${dealId}`, payload)

  return response.data
}

const dealDelete = async (dealId: DealTypes['id']) => {
  return api.delete<DealTypes>(`/deals/${dealId}`)
}

const dealInvoicePost = async (
  dealId: DealTypes['id'],
  payload: Partial<DealTypes>
) => {
  const { data: response } = await licenseApi.post<
    ApiResponseTypes<InvoiceTypes>
  >(`/deals/${dealId}/invoice`, payload)

  return response.data
}

export default {
  get: dealGet,
  post: dealPost,
  patch: dealPatch,
  delete: dealDelete,
  postInvoice: dealInvoicePost
}
