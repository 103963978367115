import { ApiResponseTypes, licenseApi } from 'services'
import { InvoiceTypes } from './Invoices.types'

const invoiceGet = async (invoiceId: InvoiceTypes['id']) => {
  const { data: response } = await licenseApi.get<
    ApiResponseTypes<InvoiceTypes>
  >(`/invoices/${invoiceId}`)

  return response.data
}

const invoiceDelete = async (invoiceId: InvoiceTypes['id']) => {
  return licenseApi.delete<InvoiceTypes>(`/invoices/${invoiceId}`)
}

export default {
  get: invoiceGet,
  delete: invoiceDelete
}
