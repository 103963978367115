import React, { useEffect } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Outlet, useSearchParams } from 'react-router-dom'
import {
  MenuOutlined,
  MenuTwoTone,
  AddToPhotosOutlined,
  AddToPhotosTwoTone,
  AssignmentTurnedInOutlined,
  AssignmentTurnedInTwoTone,
  LibraryBooksOutlined,
  LibraryBooksTwoTone,
  PieChartOutlined,
  PieChartTwoTone,
  PictureAsPdfOutlined,
  PictureAsPdfTwoTone,
  EmojiEmotionsOutlined,
  EmojiEmotionsTwoTone,
  PaymentOutlined,
  PaymentTwoTone,
  HelpOutlineOutlined,
  HelpTwoTone,
  TryOutlined,
  TryTwoTone,
  DescriptionOutlined,
  DescriptionTwoTone
} from '@mui/icons-material'

import { useFeedback, useLayout } from 'context'
import { ROUTES } from 'router'
import { useDevice } from 'hooks'

import { AsideFeedbackComponent } from 'layout/components/aside/components'
import { TEACHER_TYPES } from 'services'
import {
  HeaderComponent,
  AsideComponent,
  SkipLinkComponent,
  BottomNavigationComponent
} from '../../components'

import {
  LayoutContentStyles,
  LayoutMainStyles,
  LayoutStyles
} from './DashboardLayout.styles'
import { DashboardLayoutPropTypes, NavItemTypes } from './DashboardLayout.types'

export const DashboardLayout: React.FC<DashboardLayoutPropTypes> = ({
  isAsideHidden = false,
  isHeaderHidden = false
}) => {
  const { t } = useTranslation()
  const { dialog } = useFeedback()
  const { isMobileDevice } = useDevice()
  const { asideMenuHandlers, feedbackModalHandlers } = useLayout()
  const [urlParams] = useSearchParams()

  const status = urlParams.get('status')
  const message = urlParams.get('message') || undefined
  const action = urlParams.get('action')
  const actionLabel = urlParams.get('action_label')

  const hasAction = action && actionLabel

  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))
  const isDesktopScreen = useMediaQuery(useTheme().breakpoints.up('md'))

  const NAV_ITEMS: NavItemTypes[] = [
    {
      id: 0,
      name: 'main-menu',
      title: {
        main: 'navigation.mainMenu.title'
      },
      to: null,
      onClick: asideMenuHandlers.onToggle,
      icon: MenuOutlined,
      filledIcon: MenuTwoTone,
      hideInLeftNavigation: true,
      hideElement: !isMobileDevice
    },
    {
      id: 1,
      name: 'create-assignment',
      title: {
        main: 'navigation.assignments.create.title'
      },
      to: `/${ROUTES.CREATE_ASSIGNMENT}`,
      icon: AddToPhotosOutlined,
      filledIcon: AddToPhotosTwoTone,
      showInBottomNavigation: true
    },
    {
      id: 2,
      name: 'assignment',
      title: {
        main: 'navigation.assignments.title'
      },
      to: `/${ROUTES.ASSIGNMENT}`,
      icon: AssignmentTurnedInOutlined,
      filledIcon: AssignmentTurnedInTwoTone,
      showInBottomNavigation: true
    },
    {
      id: 3,
      name: 'library',
      title: {
        main: 'navigation.library.title'
      },
      to: `/${ROUTES.LIBRARY}`,
      icon: LibraryBooksOutlined,
      filledIcon: LibraryBooksTwoTone
    },
    {
      id: 4,
      name: 'reports',
      title: {
        main: 'navigation.reports.title'
      },
      to: `/${ROUTES.REPORTS}`,
      icon: PieChartOutlined,
      filledIcon: PieChartTwoTone,
      showInBottomNavigation: true
    },
    {
      id: 5,
      name: 'offline-content',
      title: {
        main: 'navigation.offlineContent.title',
        mobile: 'navigation.offlineContent.titleMobile'
      },
      to: `/${ROUTES.OFFLINE_CONTENT}`,
      icon: DescriptionOutlined,
      filledIcon: DescriptionTwoTone
    },
    {
      id: 6,
      name: 'PDF_GENERATORS',
      title: {
        main: 'navigation.pdfGenerator.title'
      },
      to: `/${ROUTES.PDF_GENERATOR}`,
      icon: PictureAsPdfOutlined,
      filledIcon: PictureAsPdfTwoTone
    },
    {
      id: 7,
      name: 'groups',
      title: {
        main: 'navigation.groups.title'
      },
      to: `/${ROUTES.GROUPS}`,
      icon: EmojiEmotionsOutlined,
      filledIcon: EmojiEmotionsTwoTone,
      showInBottomNavigation: true
    },
    {
      id: 8,
      name: 'license',
      title: {
        main: 'navigation.license.title'
      },
      teacherType: TEACHER_TYPES.MANAGER,
      to: `/${ROUTES.LICENSE}`,
      icon: PaymentOutlined,
      filledIcon: PaymentTwoTone
    },
    {
      id: 9,
      name: 'help-support',
      title: {
        main: 'navigation.helpAndSupport.title'
      },
      to: `/${ROUTES.HELP_SUPPORT}`,
      icon: HelpOutlineOutlined,
      filledIcon: HelpTwoTone
    },
    {
      id: 10,
      name: 'feedback',
      title: {
        main: 'navigation.feedback.title'
      },
      to: null,
      onClick: feedbackModalHandlers.onToggle,
      icon: TryOutlined,
      filledIcon: TryTwoTone,
      position: 'bottom'
    }
  ]

  useEffect(() => {
    if (status === 'login_failed') {
      dialog.show({
        content: {
          title: t('general.status.error.somethingWentWrong'),
          subtitle: message || undefined,
          footer:
            (hasAction && {
              visible: true,
              align: 'flex-end',
              buttons: [
                {
                  value: actionLabel,
                  onClick: () => {
                    window.location.href = action
                  },
                  variant: 'contained'
                }
              ]
            }) ||
            undefined
        },
        status: 'error'
      })
    }
  }, [urlParams])

  return (
    <LayoutStyles>
      <SkipLinkComponent />
      {!isHeaderHidden && <HeaderComponent items={NAV_ITEMS} />}
      <LayoutContentStyles>
        <AsideFeedbackComponent
          open={feedbackModalHandlers.isOpen}
          onClose={feedbackModalHandlers.onClose}
          onOpen={() => feedbackModalHandlers.onOpen}
          isMobile={isMobileScreen}
        />
        {isDesktopScreen && !isAsideHidden && (
          <AsideComponent items={NAV_ITEMS} />
        )}
        <LayoutMainStyles isAsideHidden={isAsideHidden}>
          <Outlet />
        </LayoutMainStyles>
      </LayoutContentStyles>
      {isMobileScreen && <BottomNavigationComponent items={NAV_ITEMS} />}
    </LayoutStyles>
  )
}

export * from './DashboardLayout.types'
