import React from 'react'
import { Typography } from '@mui/material'
import { ModalComponent, PopUpPropTypes } from 'components'
import {
  PopUpContainerStyles,
  PopUpHeaderStyles,
  PopUpContentStyles,
  PopUpFooterStyles
} from './PopUpModal.styles'
import { ResponseModalTemplate } from './templates'

import { ActionGroupComponent } from './components'

export const resolveTemplate = (data: PopUpPropTypes) => {
  if (!data.template) return <Typography>{data.content}</Typography>

  if (data.template === 'response') return <ResponseModalTemplate {...data} />
  return <div />
}

export const PopUpComponent: React.FC<PopUpPropTypes> = ({
  children,
  ...props
}) => {
  const {
    title,
    onClose,
    onConfirm,
    open,
    variant = 'light',
    footer = {
      visible: true,
      align: 'flex-end'
    },
    disablePadding = false,
    zIndexPriority
  } = props

  return (
    <ModalComponent
      scroll={props.scroll}
      open={open}
      onClose={onClose}
      zIndexPriority={zIndexPriority}
    >
      <PopUpContainerStyles>
        {variant !== 'noHeader' && (
          <PopUpHeaderStyles variant={variant}>
            <Typography>{title}</Typography>
          </PopUpHeaderStyles>
        )}
        {children && (
          <PopUpContentStyles disablePadding={disablePadding}>
            {children}
          </PopUpContentStyles>
        )}
        {footer.visible && (
          <PopUpFooterStyles
            disablePadding={disablePadding}
            justifyContent={footer.align}
          >
            {footer.content ? (
              footer.content(
                <ActionGroupComponent
                  onConfirm={onConfirm}
                  onClose={onClose}
                  footer={footer}
                />
              )
            ) : (
              <ActionGroupComponent
                onConfirm={onConfirm}
                onClose={onClose}
                footer={footer}
              />
            )}
          </PopUpFooterStyles>
        )}
      </PopUpContainerStyles>
    </ModalComponent>
  )
}

export * from './PopUpModal.types'
export * from './templates'
