export default {
  layout: {
    skipLink: {
      overall: 'Skip to {{ content }}',
      header: 'header',
      aside: 'menu pages',
      main: 'content'
    }
  },
  search: {
    placeholder: {
      default: 'Search...',
      assignments: 'Search for assignments...',
      students: 'Search for students...',
      class: 'Search for class...',
      games: 'Search for games...'
    },
    noOptions: {
      default: 'No options'
    },
    suggestions: {
      title: 'Suggestions'
    },
    loading: {
      default: 'Loading...'
    }
  },
  button: {
    template: {
      addNewGroup: {
        text: 'Add new group'
      }
    },
    text: {
      viewResults: 'View Results',
      report: 'Report',
      edit: 'Edit',
      delete: 'Delete',
      newAssignment: 'New assignment',
      createNewGroup: 'Create a new group',
      selectLanguage: 'Select a language',
      createNewAssignment: 'Create new assignment',
      reports: 'Reports',
      moreInfo: 'More info',
      readMore: 'Read more',
      offlineContent: 'Offline games',
      buyLicenses: 'Buy licenses',
      assignLicenses: 'Assign licenses',
      manage: 'Manage',
      addCustomer: 'Add customer',
      addLicenses: 'Add licenses',
      addInvoice: 'Add invoice',
      successfullyAdded: 'Successfully added'
    }
  },
  groupListItem: {
    students: '{{ studentCount }} students'
  },
  gameCard: {
    more: 'More',
    topics: 'Topics',
    skills: 'Skills:',
    tags: 'Tags:',
    boards: '{{ boardsCount }} tasks',
    chooseGameModes: 'Choose game modes:',
    chooseModes: 'Choose modes',
    planet: 'Planet: {{ planetName }}',
    needsSchoolLicense: 'Requires a Numetry School license'
  },
  modals: {
    confirmation: {
      assignment: {
        create: {
          title: 'Confirmation',
          subtitle:
            '<strong>{{ name }}</strong> has been added to your assignments list'
        },
        update: {
          title: 'Changes saved',
          subtitle:
            '<strong>{{ name }}</strong> has been updated with your changes.'
        }
      }
    },
    error: {
      assignment: {
        create: {
          title: 'Unexpected Error',
          subtitle:
            'Something went wrong creating the new assignment. Please try again later'
        },
        update: {
          title: 'Unexpected Error',
          subtitle:
            'Something went wrong updating the assignment. Please try again later'
        }
      }
    },
    gameDetails: {
      topics: 'Topics:',
      skills: 'Skills:',
      gameModes: 'Modes:',
      tags: 'Tags:',
      levelInfo: 'Level Info',
      preClassroom: 'Pre Classroom',
      afterPlaying: 'After Playing',
      whatTheKidsShouldDoNow: 'What’s next?',
      relatedGames: 'Related games',
      tasks: '{{ nrOfTasks }} tasks',
      curriculumObjectives: 'Curriculum objectives',
      funFact: 'Fun Fact',
      missionDetails: 'Mission Details',
      selectModes: 'Select modes',
      planet: 'Planet: {{ planetName }}',
      modesSubtitle:
        'Below you will find an overview of the academic content in the various modes. To assign assignments to your students, press “Create an assignment” in the main menu.',
      readTheGaneHelpArticle: 'Read the {{ gameName }} help article'
    },
    assignTo: {
      title: 'Add students',
      subtitle:
        'Choose which students or groups you would like to receive this assignment.',
      wholeClass: 'Whole class',
      students: 'Students',
      groups: 'Groups'
    },
    editGroup: {
      title: 'Edit group',
      groupLabel: 'Group name',
      enterGroupName: 'Enter a group name',
      studentsInThisGroup: 'Students in this group',
      addedToThisGroup: 'added to this group',
      studentCount_one: '<strong>{{ count }} student</strong>',
      studentCount_other: '<strong>{{ count }} student</strong>',
      findStudentsToAdd: 'Find students to add',
      showingNStudents: 'Showing {{ count }} students',
      showingNStudents_zero: 'No student found',
      groupSelectPlaceholder: 'Choose group',
      errorMessage: {
        pleasePutGroupName: 'Please, enter a group name'
      }
    },
    createGroup: {
      title: 'Create group',
      groupLabel: 'Group name',
      table: {
        name: 'Students added to group',
        userGroups: 'Feide class'
      },
      input: {
        placeholder: 'Enter a group name'
      },
      filters: {
        select: 'Select by group',
        search: 'Search by student'
      },
      button: {
        confirm: 'Save group'
      }
    }
  },
  slider: {
    from: 'From',
    to: 'To'
  },
  userTableRow: {
    results: "{{ name }}'s results"
  },
  table: {
    pagination: {
      previous: 'Previous',
      next: 'Next'
    },
    notFoundImageAlt: 'Girl confused',
    notFoundTip: 'Try searching for a different {{ entity }} title',
    noResultsFound: 'No results found',
    noResultsFoundDescription:
      'No results were found for this table, try to create a new item or refresh the page',
    noRowsWithoutSearch: 'No {{ entity }} found',
    noRowsWithSearch: 'No {{ entity }} found for “{{ search }}”'
  },
  picker: {
    from: 'From',
    to: 'To'
  },
  pdfs: {
    symbols: {
      equals: {
        symbol: '=',
        name: 'equals'
      },
      multiplication: {
        symbol: '×',
        name: 'multiplication'
      },
      division: {
        symbol: '÷',
        name: 'division'
      },
      fraction: {
        symbol: '/',
        name: 'fraction'
      },
      addition: {
        symbol: '+',
        name: 'addition'
      },
      subtraction: {
        symbol: '-',
        name: 'subtraction'
      }
    },
    worksheet: {
      name: 'Name',
      class: 'Class',
      answerSheet: {
        title: 'Answer Key'
      }
    }
  }
}
